import { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
    const [bots, setBots] = useState([]);
    const [currentBot, setCurrentBot] = useState(null);

    const refreshBot = () => {
        axios.get('https://fagex-overseer.bitgate.workers.dev/api/v1/bots').then(r => {
            setBots(r.data.filter(bot => bot.name && bot.name !== 'null'));
        });
    };

    useEffect(() => {
        refreshBot();
        const key = window.setInterval(refreshBot, 1000);

        return () => window.clearInterval(key);
    }, []);

    if (bots.length && currentBot === null) {
        setCurrentBot(0);
    }

    return (
        <div className="App">
            <div className="container">
                <div className="sidebar">
                    <h3>Active Bots</h3>
                    {bots.map((bot, i) =>
                        <div className="bot-entry" key={bot.name}>
                            <button onClick={() => setCurrentBot(i)}>{bot.name}</button>
                        </div>
                    )}
                </div>

                {currentBot !== null ? <BotDetails bot={bots[currentBot]} /> : null}
            </div>

        </div>
    );
}

const BotDetails = (props) => {
    const bot = props.bot;

    return (
        <div className="main">
            <h2>{bot.name}</h2>

            <div className="screenshot">
                <img src={`https://fagex-overseer.bitgate.workers.dev/api/v1/screenshots/${encodeURIComponent(bot.name)}/${bot.screenshot}`} alt=""></img>
            </div>
        </div>
    )
}

export default App;
